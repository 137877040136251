<template>
  <div class="test">
    <div class="t">
      <pdf :src="src" :page="currentPage"> </pdf>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "test",
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1, // pdf文件页码
      pageCount: 15, // pdf文件总页数
      src: "", // pdf文件地址
    };
  },

  created() {
    this.getdetectionPdf();
  },

  methods: {
    async getdetectionPdf() {
      const data = await this.$API.pdf.getdetectionPdf({
        object: {
          barcode: "6951184200196",
          enterpriseId: "613748F65B9B4824A8DBB05045F0E19A",
        },
      });
      console.log(data.data.data);
      let path = data.data.data.url.split("//")[1];
      this.src = `http://14.29.162.130:6603/file/fileDownload/${path} `;
      console.log(this.src);
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  display: flex;
  justify-content: center;
  .t {
    width: 1000px;
    // height: 1200px;
    background: #fff;
  }
}
</style>